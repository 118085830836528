import './Homepage.css';

import * as React from 'react'

import ContactCard from './Sections/contact/ContactCard';
import ExperienceCard from './Sections/experience/ExperienceCard';
import Linkbar from './Linkbar/Linkbar';
import Scoreboard from './Scoreboard/Scoreboard';
import SkillsCard from './Sections/skills/SkillsCard';
import WelcomeCard from './Sections/welcome/WelcomeCard';

class Homepage extends React.Component {

    render() {
        return (
            <div>
                <Linkbar />
                <Scoreboard />
                <div className="scrollable" id="scrl">
                    <WelcomeCard />
                    <ExperienceCard />
                    <SkillsCard />
                    <ContactCard />
                </div>
            </div>
        );
    }
}

export default Homepage;