import './ExperienceCard.css'

import ExpCardImages from '../../../components/experience/cards/index';
import Rafters from '../../../components/experience/Rafters.png';
import React from 'react';

/* Experiences and their values */

interface Experience {
    company: string,
    year: string,
    title: string,
    team: string,
    description: string[],
    cardID: number
}

const CAPITALG: Experience = {
    company: "@ Capital",
    year: "August 2021 - Present",
    title: "Software Engineer",
    team: "Private Client Services",
    description: ["Finding out soon!","¯\\_(ツ)_/¯"],
    cardID: 1
};

const SNAPCHAT: Experience = {
    company: "@ Snap",
    year: "Summer 2020",
    title: "SWE Intern",
    team: "Backend Camera Team",
    description: ["Developed mobile feature to artificially improve clarity of front and back camera captures in low-light environments based on UX research (reviews, social media, etc.) and image denoising algorithms", "Collaborated remotely across multiple teams to push feature into user testing despite newly developed remote format", "Stack: Java, Kotlin"],
    cardID: 2
};

const UBER: Experience = {
    company: "@ Uber",
    year: "Summer 2019",
    title: "SWE Intern",
    team: "Rider Growth Team",
    description: ["Engineered full stack promotion messaging solution to drastically increase range of information presented to over 90 million users increasing internal B2B by a recorded 4% (Uber to Uber Eats)",  "Modified promotional messaging storage to introduce historical collection, allowing for machine learning integration that targets advertisements unique to users", "Stack: Go, Javascript"],
    cardID: 5
};

const COHANRG: Experience = {
    company: "@ CRG",
    year: "Summer 2018",
    title: "Data Science Intern",
    team: "Houston Engagement and Recovery Effort (H.E.R.E) Research",
    description: ["Applied regression analysis and modeling to meteorological data to evaluate effects of Hurricane Harvey and future storms on pollutant levels affecting 2.3 million Houston residents", "Presented key findings at a research symposium focused on hurricane recovery and community empowerment to an audience of 250 climate professionals", "Stack: Python, MATLAB"],
    cardID: 7
};

/* Card Stat Decoration */

function CardStats() {
    return (
        <div className="card-stats">
            <span id="player" >PLAYER</span>
            <span id="min">MIN</span>
            <span id="fgm">FGM</span>
            <span id="fga">FGA</span>
            <span id="reb">REB</span>
            <span id="ast">AST</span>
            <span id="stl">STL</span>
            <span id="blk">BLK</span>
            <span id="pts">PTS</span>
        </div>
    );
}


var exps: Experience[] = [CAPITALG, SNAPCHAT, UBER, COHANRG]

class IndividualCards extends React.Component<{}, {top: number}> {

    // Elements are indexed in order to correctly access the two
    // images representing their selected and non-selected states.
    // Therefore, card 1 has images 0,1, card 2: 2,3, card 3:, 4,5,
    // and so on.

    constructor(props: any) {
        super(props);
        // Starts with 2nd entry prioritized
        this.state = {top: 1};
    }
    
    zChange(id: number) {
        console.log(id);
        if (this.state.top !== id) {
            exps.forEach((ex, i) => {
                if (i === id) {
                    // Change z val for id card to highest
                    // Change img for card to selected
                    ex.cardID = (i * 2);
                    this.setState({top: i});
                } else {
                    // Change img for all other cards to non-selected and lower zindices
                    ex.cardID = (i * 2) + 1;
                }
            });
        }
    }

    resize = () => this.forceUpdate();

    componentDidMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    render() {
        return (
                <div className="score-card">
                    
                    <img id="score-card-img" src={ExpCardImages[this.state.top]} alt="Scorecard Missing" draggable="false"/>
                    
                    <div className="buttonGrid">
                        {exps.map((exp, idx) => {return (
                                <button className="zswitch" onClick={() => this.zChange(idx)} style={{gridColumn: "span 1"}} key={idx}></button>
                        )})}  
                    </div>
                    
                    <div className="score-info">
                        <CardStats />
                        <span className="siteText card-year">{exps[this.state.top].year}</span>
                        <span className="siteText card-title">{exps[this.state.top].title}</span>
                        <span className="siteText card-team">{exps[this.state.top].team}</span>
                        <ul className="siteText card-description">
                            <br/>
                            {exps[this.state.top].description.map(
                                item => { return (
                                    <li key={item}>{item}<br/><br/></li>
                                )})}
                        </ul>
                    </div>
                    
                </div>
        );
    }
}

export default function ExperienceCard() {
    
    return (
        <>
            <div className="homepage-card" id="c2">
                <span className="siteText siteTitle">
                    Experience
                </span>
                <span className="siteText siteParagraph">
                    Previous teams I've played for.
                </span>
                <br/>
                <div className="exp-div">
                    <IndividualCards />
                    <div className="exp-collegiate">
                        <img id="rafters" src={Rafters} alt="Rafters Missing" draggable="false"/>
                        <span className="siteText coll-title">Rice University</span>
                        <span className="siteText coll-info">Computer Science, B.A, Cognitive Science, B.A.</span>
                </div>
                </div>
            </div>
        </>
    );
}
