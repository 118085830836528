import './Scoreboard.css'

import React, { useEffect, useState } from 'react';

import ScoreArrow from '../../components/scoreboard/ScoreArrow.png';
import ScoreBoardBack from '../../components/scoreboard/ScoreboardBack.png';
import { useRef } from 'react';

export default function Scoreboard() {

    // State value for score
    const [score, setScore] = useState(1);
    
    // Ref flag to deterrmine if mounted already
    let isMounted = useRef(false);
    
    // Delay flag used to limit arrow press frequency
    let pressDelay = useRef(false);

    let enactDelay = () => {
        console.log("delay began");
        pressDelay.current = true;
        setTimeout(() => {
            pressDelay.current = false;
            console.log("delay concluded: " + !pressDelay.current);
        }, 200);
    };

    // Shift score up in order to move down on button press
    function moveDown() {
        console.log("pressed");
        if (score < 4 && !pressDelay.current) {
            console.log("allowed");
            setScore(score => score + 1);
            enactDelay();
        }
    }

    // Shift score down in order to move up on button press
    function moveUp() {
        console.log("pressed");
        if (score > 1 && !pressDelay.current) {
            console.log("allowed");
            setScore(score - 1);
            enactDelay();
        }
    }

    // useEffect function to update score text, dependent on any change to score
    useEffect(
        () => {
            // console.log("A. score change scroll effect called");
            if (isMounted.current) {
                // Scrolls so that numbered section is in view
                const element = document.getElementById("c"+score.toString());
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            } else {
                isMounted.current = true;
            }
        }, [score]
    );

    // useEffect function to update score text on any change scroll change pause
    useEffect(
        () => {
            // console.log("B. scroll change score effect called");
            if (document.getElementById("scrl")) {
                // If there is no forced delay due to an already ensuing movement...
                // console.log("Are we clear to scroll?: "+!pressDelay.current);
                
                // If statement to ensure delay is inactive
                if (!pressDelay.current) {
                    var timer: NodeJS.Timeout;
                    
                    var scrollCheck = () => {
                        if(timer !== null) {
                            // If timer hasnt stopped, restart it, as we are still scrolling so a new one needs to be set
                            clearTimeout(timer);
                            // console.log("Timer reset");
                            
                        }
                        
                        timer = setTimeout(() => {
                            // If timeout finishes, update score. Run after 100ms
                            setScore(1 + Math.round(document.getElementById("scrl")!.scrollTop / (window.innerHeight)));
                            // console.log("Timer clear");
                        }, 100);
                    }
                    
                    // Adds event listener for scroll that is removed when event concludes, meaning another has started?
                    document.getElementById("scrl")!.addEventListener('scroll', scrollCheck);
                    
                    return () => {
                        document.getElementById("scrl")!.removeEventListener('scroll', scrollCheck)
                    };
                }
            }
        }, []
    );

    return(
        <>
            <div className="scoreboard">
                <img className="score-arrow arrow-up" src={ScoreArrow} onClick={moveUp} alt="Logo Missing" style={{visibility: score === 1? "hidden": "visible"}} draggable="false"/>
                <div className="score-back">
                    <img className="scoreboard-img" src={ScoreBoardBack} alt="Logo Missing" draggable="false"/>
                    <span className="score-number">{"0"+score}</span>
                </div>
                <img className="score-arrow arrow-down" src={ScoreArrow} onClick={moveDown} alt="Logo Missing" style={{visibility: score === 4? "hidden": "visible"}} draggable="false"/>
            </div>
        </>
    );
}