import './SkillsCard.css'

import React from 'react';
import SkillCourt from '../../../components/skills/SkillCourt.svg';

export default function SkillsCard() {
    
    return (
        <>
            <div className="homepage-card" id="c3">
                <span className="siteText siteTitle">
                    Skills
                </span>
                <span className="siteText siteParagraph">
                    My current bag of tricks.
                </span>
                <br/>
                <img id="skill-board-img" src={SkillCourt} alt="Skill board missing" draggable="false"/>
            </div>
        </>
    );
}