import './App.css';

import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';

import Homepage from './homepage/Homepage';
import Navbar from './navbar/Navbar';
import React from 'react';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Homepage}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
