// Index file that exports all images in folder

import A from "./ASelect.svg";
import B from "./BSelect.svg";
import C from "./CSelect.svg";
import D from "./DSelect.svg";

const expCardImages = [
    A,
    B,
    C,
    D,
];

export default expCardImages;
