import './Navbar.css'

import { Link, useHistory } from "react-router-dom";

import Logo from '../components/Logo.svg'
import React from 'react';

export default function Navbar() {

    const history = useHistory();

    const resumeDownload = () => {
        var temp = document.createElement('a');
        temp.setAttribute('href', '/myfiles/Tim Thomas II.pdf');
        temp.setAttribute('download', "Tim Thomas Resume");

        temp.style.display = 'none';
        document.body.appendChild(temp);

        temp.click();

        document.body.removeChild(temp);
    }

    return (
        <div className="navbar">
            <Link to='/'>
                <img id="logo-image" src={Logo} alt="Logo Missing"/>
            </Link>
            <div className="toolbar">
                <button className="nav-buttons personal-button">Personal</button>
                
                <button className="nav-buttons professional-button" onClick={() => {history.push("/")}}>Professional</button>
                
                <button className="nav-buttons resume-button" onClick={resumeDownload}>Resume</button>
            </div>
        </div>
    );
}
