import './Linkbar.css'

import Ballrack from '../../components/linkbar/Ballrack.svg'
import Ballrow from '../../components/linkbar/Ballrow.svg'
import React from 'react';

export default class Linkbar extends React.Component {

    render() {
        return(
            <div>
                <div className="linkbar-div">
                    <img className="rack-image" src={Ballrack} alt="Logo Missing"/>

                    <img className="ball-row-image" src={Ballrow} alt="Logo Missing"/>
                    
                    <a href="https://github.com/timthomas02" target="_blank" rel="noopener noreferrer">
                        <div className="link-ball github-div"></div>
                    </a>
                    <a href="https://instagram.com/timthomasii" target="_blank" rel="noopener noreferrer">
                        <div className="link-ball instagram-div"></div>
                    </a>
                    <a href="https://www.linkedin.com/in/tim-thomas-ii/" target="_blank" rel="noopener noreferrer">
                        <div className="link-ball linkedin-div"></div>
                    </a>
                </div>
            </div>
        );
    }
    
}