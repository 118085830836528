import './ContactCard.css'

import React from 'react';

export default function ContactCard() {
    return (
        <>
            <div className="homepage-card" id="c4">
                <span className="siteText siteTitle">
                    Contact Me
                </span>
                <span className="siteText siteParagraph">
                    Not hunting for any new opportunities at the moment, but I’m always reachable at my email below, as well as on LinkedIn.
                </span>
                <div className="contact-buttons">
                    <button className="siteButton emailButton" onClick={() => {
                        window.location.href="mailto: timothythomas1999@gmail.com";
                        }}>Email</button>
                    <button className="siteButton linkedInButton" onClick={() => {
                        window.open("https://www.linkedin.com/in/tim-thomas-ii/", "_blank");
                        }}>LinkedIn</button>
                </div>
                <div className="siteText siteParagraph credits">
                    * A Figma + React project ;)
                </div>
            </div>
        </>
    );
}
