import './WelcomeCard.css'

import CopyVector from '../../../components/welcome/CopyVector.svg'
import CopyVectorClick from '../../../components/welcome/CopyVectorClick.svg'
import React from 'react';

export default function WelcomeCard() {

    const emailAddr = "timothythomas1999@gmail.com";

    let copyClick = () => {
        navigator.clipboard.writeText(emailAddr).then(() => {
            /* clipboard successfully set */
            document.getElementById("copyVector")!.setAttribute( 'src', CopyVectorClick);
            setTimeout(() => {
                document.getElementById("copyVector")!.setAttribute( 'src', CopyVector);
            }, 2000);
            return;
          }, () => {
            /* clipboard write failed */
            return;
          });
    }

    return (
        <>
            <div className="homepage-card welCard" id="c1">
                <span className="siteText welcIntro">
                    Hi, my name is
                </span>
                <span className="siteText welcName">
                    Tim Thomas II
                </span>
                <span className="siteText siteParagraph">
                    Professionally, I’m a software engineer that enjoys designing apps that lead to positive relationships between humans & tech.<br /><br />Im currently in LA, doing this with <a className="currLocation" href="https://capitalgroup.com" target="_blank" rel="noopener noreferrer">The Capital Group.</a>
                </span>
                <div className="email">
                    <span className="siteText email-addr" style={{display: "inline"}}>
                    </span>
                    <span className="siteText copyLine"></span>
                    <img id="copyVector"src={CopyVector} style={{display: "inline"}} alt="Copy vector missing" draggable="false" onClick={copyClick}/>
                </div>
            </div>
        </>
    );
}
